@import "layout/variables.inc.css";

.feature-card {
    background-repeat: no-repeat;
    background-position: right bottom;
}

#mirror-card {
    background-image: url("../img/site/framed-mirrors.png");
    min-height: 200px;
}

#frame-card {
    background-image: url("../img/site/swept-and-ornate-frames.png");
    min-height: 200px;
}

#accessories-card {
    background-image: url("../img/site/framing-accessories.png");
    min-height: 200px;
    background-size: contain;
}

#value-card {
    background-image: url("../img/site/ultra-value-frame-ranges.png");
    min-height: 200px;
}

#quote-card {
    background-image: url("../img/site/professional-framing.png");
}

.artwork-card {
    position: relative;

    & .price-badge {
        position: absolute;
        right: 0;
        top: 10px;
    }
}

#sustainability {
    background-image: url("../img/site/backgrounds/sustainability.jpg");
    background-repeat: no-repeat;
    background-size: 265%;
    background-position: center center;
    color: white;
    
    @media(--media-sm) {
        background-size: 200%;
    }

    @media(--media-md) {
        backround-size: 150%;
    }

    @media(--media-lg) {
        background-size: 100%;
    }
}

#sustainability .blur-overlay {
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(1px);
}

#team {
    background-image: url("../img/site/team/team-photos-background.webp");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: white;

    & .blur-overlay {
        background-color: rgba(255,255,255, 0.4);
    }

    & .row:not(.plate) {
        @media(--media-xs) {
            overflow-x: auto;
            white-space: nowrap;
            display: block;

            & .col {
                display: inline-block;
                max-width: 40%;
                padding: 0;

                & span {
                    margin: 0.5rem 0;
                }
            }
        } 

        @media(--media-sm) {
            display: grid;
            grid-template-rows: repeat(2, auto);
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
        }

        @media(--media-sm) and (max-width: 767px) {
            grid-template-rows: repeat(5, auto);

            & .col:nth-child(10n-4) {
                padding-top: 1rem;
            }

            & .col:nth-child(10n-3) {
                padding-top: 1rem;
            }

            & .col:nth-child(10n-2) {
                padding-top: 1rem;
            }

            & .col:nth-child(10n-1) {
                padding-top: 1rem;
            }

            & .col:nth-child(10n) {
                padding-top: 1rem;
            }

            & span {
                margin: 0.5rem 0;
            }
        }

        @media(--media-md) and (max-width: 1199px) {
            grid-template-rows: repeat(4, auto);

            & .col:nth-child(8n-3) {
                padding-top: 2rem;
            }

            & .col:nth-child(8n-2) {
                padding-top: 2rem;
            }

            & .col:nth-child(8n-1) {
                padding-top: 2rem;
            }

            & .col:nth-child(8n) {
                padding-top: 2rem;
            }

            & span {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }

        
        @media(--media-lg) and (max-width: 1199px) {
            & span {
                margin-left: 1.5rem;
                margin-right: 1.5rem;
            }
        }

        @media(--media-xl) and (max-width: 1365px) {
            grid-template-rows: repeat(3, auto);

            & .col:nth-child(6n-2) {
                padding-top: 2rem;
            }

            & .col:nth-child(6n-1) {
                padding-top: 2rem;
            }

            & .col:nth-child(6n) {
                padding-top: 2rem;
            }

            & span {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }

        @media(--media-xxl) {
            grid-template-rows: repeat(2, auto);

            & .col:nth-child(4n-1) {
                padding-top: 2rem;
            }

            & .col:nth-child(4n+4) {
                padding-top: 2rem;
            }

            & span {
                margin-left: 0.75rem;
                margin-right: 0.75rem;
            }
        }

        & .col {
            & img:not(.certified-logo) {
                box-shadow: var(--shadow-3);
            }

            & img.certified-logo {
                filter: drop-shadow(0 0.375rem 0.375rem rgba(0,0,0,0.5));
                padding-top: 1rem;
            }

            & span {
                background-image: url("../img/site/team/nameplate.webp");
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                color: black;

                display: block;

                margin-top: 0.5rem;
            }

            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}
