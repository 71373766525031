:root {
    --brandColour: #a61d26;
    --brandColourDarker: #800000;
    --brandColourLighter: #9a1a1a;

    --textColour: #212529;
    --textMutedColour: rgba(0,0,0,0.7);

    --borderColour: #eeeeee;
    --borderColourMuted: #6c757d;

    --hoverBgColour: #f8f9fa;

    --viewport-sm: 540px;
    --viewport-md: 720px;
    --viewport-lg: 960px;
    --viewport-xl: 1140px;
    --viewport-xxl: 1600px;

    --shadow-1: 0 0.016rem 0.1875rem rgba(0,0,0,0.12), 0 0.016rem 0.032rem rgba(0,0,0,0.24);
    --shadow-2: 0 0.1875rem 0.375rem rgba(0,0,0,0.16), 0 0.1875rem 0.375rem rgba(0,0,0,0.23);
    --shadow-3: 0 0.625rem 1.25rem rgba(0,0,0,0.19), 0 0.375rem 0.375rem rgba(0,0,0,0.23);
    --shadow-4: 0 0.875rem 1.75rem rgba(0,0,0,0.25), 0 0.625rem 0.625rem rgba(0,0,0,0.22);
    --shadow-5: 0 1.1875rem 2.375rem rgba(0,0,0,0.30), 0 0.9375rem 10.032rem rgba(0,0,0,0.22);

}

@custom-media --media-xs (max-width: 575px);
@custom-media --media-sm (min-width: 576px);
@custom-media --media-md (min-width: 768px);
@custom-media --media-lg (min-width: 992px);
@custom-media --media-xl (min-width: 1200px);
@custom-media --media-xxl (min-width: 1366px);